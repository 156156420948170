import React, { useEffect, useState } from "react";
import Button from "./Button";
import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray,
} from "react-hook-form";
import InputField from "./InputField";
import ProductHeader from "./ProductHeader";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import axios, { Axios } from "axios";
import { useMutation, useQuery } from "react-query";
import { translate } from "@vitalets/google-translate-api";
import addIcon from "../assets/image/Add.svg";
import dltIcon from "../assets/image/Delete.svg";
import deleteIcon from "../assets/image/deleteIcon.svg";
import Add from "../assets/image/Add.svg";
import AlertModal from "./AlertModal";

export default function AddProduct() {
  // const dispatch=useDispatch()
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userForm: [
        {
          productNameEnglish: "",
          productImage: "",
          quantity: "",
          quantityType: "",
          unitPrice: "",
          stock: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "userForm",
    control,
  });
  const [open, setOpen] = useState(false);
  const [UpdateOpen, setUpdateOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  console.log("errorOpen", errorOpen);

  const [base64Image, setBase64Image] = useState(null);
  // const handleImageInputChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       const base64String = reader.result;
  //       setBase64Image(base64String);
  //     };
  //     reader.onerror = (error) => {
  //       console.error(
  //         "Error occurred while converting image to base64:",
  //         error
  //       );
  //     };
  //   }
  // };
  const navigate = useNavigate();

  const { state } = useLocation();
  let base64String = state && state?.productImage;
  console.log("state?.productImage");
  useEffect(() => {
    Array(state).map((ele, ind) => {
      console.log("ele", ind);
      setValue(`userForm.${ind}.productNameEnglish`, ele?.productNameEnglish);
      setValue(`userForm.${ind}.productImage`, ele?.productImage);
      setValue(`userForm.${ind}.productNameTamil`, ele?.productNameTamil);
      setValue(`userForm.${ind}.quantity`, ele?.quantity);
      setValue(`userForm.${ind}.quantityType`, ele?.quantityType);
      setValue(`userForm.${ind}.discountOnUnitPrice`, ele?.discountOnUnitPrice);
      // setValue("OfferOnUnitPrice", ele?.OfferOnUnitPrice);
      setValue(`userForm.${ind}.offerSelectFromTo`, ele?.offerSelectFromTo);
      setValue(`userForm.${ind}.unitPrice`, ele?.unitPrice);
      setValue(`userForm.${ind}.actualUnitPrice`, ele?.actualUnitPrice);
      setValue(`userForm.${ind}.stock`, ele?.stock);
    });
  }, [state]);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(`${process.env.REACT_APP_HOST}/products/addProduct`, postData),
    {
      onSuccess: (data) => {
        setOpen("Product has been successfully added.");
      },
      onError: (error) => {
        // alert(
        // error &&
        //   error?.response &&
        //   error?.response?.data &&
        //   error?.response?.data
        // );
        setErrorOpen(
          error &&
            error?.response &&
            error?.response?.data &&
            error?.response?.data
        );

        console.error("Mutation failed:", error);
      },
    }
  );
  const updata = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/products/${state?.id}`,
        postData
      ),
    {
      onSuccess: (data) => {
        // Handle successful mutation
        // alert("Product Update Successfully");
        // reset();
        // navigate("/ProductView/ProductTable");
        setUpdateOpen("Product has been successfully updated.");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
        setErrorOpen(
          error &&
            error?.response &&
            error?.response?.data &&
            error?.response?.data
        );
      },
    }
  );
  const Submit = async (data) => {
    console.log("For_data", data?.userForm);
    if (state && state?.id) {
      updata.mutate(data?.userForm[0]);
    } else {
      mutate(data?.userForm);
      console.log("data", data);
    }
  };

  // Define mutation function using useMutation hook
  const { userForm, productNameEnglish } = watch();
  console.log("productNameEnglish", userForm);
  // console.log('productImage',base64Image==null&&!state&&state?.id,base64Image==null)

  const handleEnglishChange = async (e) => {
    const text = e.target.value;
    // setEnglishText(text);
    console.log("tamil", text);
    try {
      // Translate the English text to Tamil
      const translation = await translate(text, { to: "ta" });
      console.log("VL", translation);
      setValue("productNameTamil", translation);
    } catch (error) {
      console.log("Translation error:", error);
    }
  };
  const [isImages, setIsImages] = useState(state && state.productImage);

  const handlerImage = () => {
    const confirmDelete = window.confirm("Are you want to Change the Image?");
    if (confirmDelete) {
      setIsImages(false);
    }
  };

  let { discountOnUnitPrice } = watch();
  let { quantity } = watch();
  let { unitPrice } = watch();

  const productDivValue = quantity * unitPrice - discountOnUnitPrice;

  console.log("productDivValue", productDivValue);
  useEffect(() => {
    setValue("actualUnitPrice", productDivValue);
  }, [productDivValue]);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      console.log("Enter key pressed - form not submitted");
    }
  };

  return (
    <div className="main-container">
      <ProductHeader back={true} productView={true} label="Add Product" />

      <div
        className="input-container"
        style={{
          // width:"100%",
          height: "600px",
          overflowY: "scroll",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <form
          // onKeyDown={handleKeyDown}
          onSubmit={handleSubmit(Submit)}
          // e.preventDefault()
        >
          <div className="d-flex px-1">
            <div style={{ display: "flex" }}>
              <div>
                {fields.map((field, index) => {
                  const handleImageInputChange = (event) => {
                    const file = event.target.files[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = () => {
                        const base64String = reader.result;
                        setValue(
                          `userForm.${index}.productImage`,
                          base64String
                        );
                      };
                      reader.onerror = (error) => {
                        console.error(
                          "Error occurred while converting image to base64:",
                          error
                        );
                      };
                    }
                  };
                  return (
                    <div key={field.id}>
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            // justifyContent:"center",
                            borderBottom: "2px dashed black",
                            paddingBottom: "10px",
                            width: "100%",
                          }}
                        >
                          <div className="ProductDivField">
                            <label className="ProductLabel">
                              Product Name{" "}
                              <span style={{ color: "red", fontSize: "14px" }}>
                                *
                              </span>
                            </label>
                            <Controller
                              control={control}
                              name={`userForm.${index}.productNameEnglish`}
                              // name="productNameEnglish"
                              render={(field) => (
                                // <input  className='input-componentsField '  placeholder='Enter Product Name English' type='text' onChange={(e)=>onChange(handleEnglishChange(e))} value={value}/>
                                <InputField
                                  {...field}
                                  // required={true}
                                  type="text"
                                  addProductInputCss="addProductInputCss"
                                  // placeholder="Product Name"
                                  onChange={(e) => handleEnglishChange(e)}
                                />
                              )}
                              rules={{ required: true }}
                            />
                            {/* <InputField {...register("productName")} placeholder="Enter Product Name English" type="text" /> */}
                          </div>
                          {/* <div className="ProductDivField">
                            <label className="ProductLabel">
                              Product Image
                            </label>
                            <div>
                              {isImages ? (
                                <p
                                  className="addProductInputCss"
                                  onClick={() => handlerImage()}
                                >
                                  {base64String
                                    .substring(5, base64String.indexOf(";"))
                                    .replace("/", ".")}
                                </p>
                              ) : (
                                <Controller
                                  control={control}
                                  name={`userForm.${index}.productImage`}
                                  // name="productImage"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <input
                                      style={{ padding: "7px" }}
                                      className="addProductInputCss"
                                      accept="image/*"
                                      type="file"
                                      onChange={(e) =>
                                        onChange(handleImageInputChange(e))
                                      }
                                    />
                                    // <InputField  {...field} placeholder="0.00 " type="file" />
                                  )}
                                />
                              )}
                            </div>
                          </div> */}
                          <div className="ProductDivField">
                            <label className="ProductLabel">
                              Unit Price{" "}
                              <span style={{ color: "red", fontSize: "14px" }}>
                                *
                              </span>{" "}
                            </label>
                            <Controller
                              control={control}
                              name={`userForm.${index}.unitPrice`}
                              // name="unitPrice"
                              render={(field) => (
                                <InputField
                                  {...field}
                                  addProductInputCss="addProductInputCss"
                                  // placeholder="Unit Price"
                                  type="number"
                                />
                              )}
                              rules={{ required: true }}
                            />
                            {/* <InputField type="file" /> */}
                          </div>
                          {/* </div>
                        <div
                          style={{
                            display: "flex",
                            borderBottom: "2px dashed black",
                            paddingBottom: "10px",
                          }}
                        > */}
                          <div className="ProductDivField">
                            <label className="ProductLabel">
                              Quantity{" "}
                              <span style={{ color: "red", fontSize: "14px" }}>
                                *
                              </span>
                            </label>

                            <Controller
                              control={control}
                              name={`userForm.${index}.quantity`}
                              // name="quantity"
                              render={(field) => (
                                <InputField
                                  {...field}
                                  addProductInputCss="addProductInputCss"
                                  // placeholder="Quantity"
                                  type="number"
                                />
                              )}
                              rules={{ required: true }}
                            />
                          </div>
                          <div className="ProductDivField">
                            <label className="ProductLabel">
                              Quantity Type
                              <span style={{ color: "red", fontSize: "14px" }}>
                                *
                              </span>
                            </label>

                            <Controller
                              control={control}
                              name={`userForm.${index}.quantityType`}
                              // name="quantity"
                              // render={({
                              //   field: { onChange, onBlur, value, ref },
                              // }) => (
                              render={(field) => (
                                // <select
                                //   {...field}
                                //   value={value}
                                //   onChange={onChange}
                                //   // {...field}
                                //   // className="input-datePickerField"
                                //   className="text-capitalize addProductInputCss"
                                //   // style={
                                //   //   errors.quantityType
                                //   //     ? { borderColor: "red" }
                                //   //     : {}
                                //   // }
                                //   // style={{ "text-transform": "capitalize" }}
                                // >
                                //   <option> </option>
                                //   <option value="Nos">nos</option>
                                //   <option value="Bag">bag</option>
                                //   <option value="Unit">unit</option>
                                //   <option value="Sft">sft</option>
                                // </select>
                                <InputField
                                  {...field}
                                  addProductInputCss="addProductInputCss"
                                  // placeholder="Quantity"
                                  type="text"
                                />
                              )}
                              rules={{ required: true }}
                            />
                          </div>
                          <div className="ProductDivField">
                            <label className="ProductLabel">
                              Stock{" "}
                              <span style={{ color: "red", fontSize: "14px" }}>
                                *
                              </span>
                            </label>
                            <div>
                              <Controller
                                control={control}
                                name={`userForm.${index}.stock`}
                                // name="stock"
                                // render={({
                                //   field: { onChange, onBlur, value, ref },
                                // }) => (
                                  render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                                  <select
                                    {...field}
                                    style={
                                      error ? { borderColor: "red" } : {}
                                    }
                                    value={value}
                                    onChange={onChange}
                                    className=" text-capitalize addProductInputCss"
                                  >
                                    <option value=""></option>
                                    <option value="Available" >
                                      Available
                                    </option>
                                    <option value="OutOfstock">
                                      Out Of stock
                                    </option>
                                  </select>
                                )}
                                rules={{ required: true }}
                              />
                            </div>
                          </div>
                          <div
                            style={{ height: "100px" }}
                            className="d-flex align-items-center"
                          >
                            {/* {index === 1?  */}
                            {/* :<></>} */}
                            <div>
                              {index < 0 ? (
                                <></>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      height: "100px",
                                      display: "flex",
                                      alignItems: "end",
                                      // marginLeft: "10px",
                                    }}
                                  >
                                    <img
                                      src={Add}
                                      alt=""
                                      className=" BillingAdd-AddBtn"
                                      onClick={() => {
                                        append({
                                          // Product: "select",
                                          // UnitPrice: 0,
                                          // DiscountPrice: 0,
                                          // Quantity: 0,
                                          // QuantityType: 0,
                                          // ActualUnitPrice: 0,
                                        });
                                      }}
                                    ></img>
                                  </div>
                                </>
                              )}
                            </div>
                            <img
                              style={{
                                width: "40px",
                                height: "45px",
                                display: index === 0 ? "none" : "block",
                                marginTop: "50px",
                              }}
                              // style={   `${index === 0 ? 'display:none' : null }`}
                              src={deleteIcon}
                              alt="delete"
                              {...register(`userForm.${index}.image`)}
                              onClick={() => remove(index)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              {/* <div
                style={{
                  width: "100px",
                  height: "110px",
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "center",
                  // paddingBottom: "7px",
                }}
                onClick={() => {
                  append({
                    productNameEnglish: "",
                    productImage: "",
                    quantity: "",
                    unitPrice: "",
                    stock: "",
                  });
                }}
              >
                <img src={addIcon} alt="addIcon" />
              </div> */}
            </div>
          </div>
          <div>
            {/* <input className="inputSubmit-Btn" type="submit" /> */}
            <button className="mt-4 ms-3 inputSubmit-Btn" type="submit">
              {isLoading ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      </div>
      <AlertModal
        open={open}
        setOpen={setOpen}
        // handleClose={handleClose}
        setUpdateOpen={setUpdateOpen}
        UpdateOpen={UpdateOpen}
        navigate={"/ProductView/ProductTable"}
        setErrorOpen={setErrorOpen}
        errorOpen={errorOpen}
      />
    </div>
  );
}
