import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import DatePicker from "react-datepicker";
import { useQuery } from "react-query";
import { fetchData, getDataFromStart } from "../api/GlobalApi";
import moment from "moment";
import axios from "axios";

  // Perform some asynchronous operation, like fetching data from an API
  // const response = await axios.get('${process.env.REACT_APP_HOST}/dataFromStart',{
  //   params: params
  // });
  // const data = response;
  // return data;


const StackedBarChart = () => {
  
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
 
  const [isDate, setIsDate] = useState("firstmonth");

  const currentDate = moment().format("YYYY-MM-DD");

  // Start of the month
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const [filter, setFilter] = useState([currentDate, moment().subtract(1, 'months').format('YYYY-MM-DD')]);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setIsDate("");
  
  };

  // const { data, isLoading, isError }  = useQuery(
  //   ["getCreditReports", filter ],
  //   getDataFromStart({ date: filter })
  // )

// API Startt
console.log('endDate',endDate,startDate,filter);
useEffect(()=>{
  let start=moment(startDate).format("YYYY-MM-DD")
  let end=moment(endDate).format("YYYY-MM-DD")
  console.log('Start_date',start,end);
  if(endDate!==null&&startDate!==null){

    setFilter(start,end)
  }
},[startDate,endDate])

const params = {
  fromDate: filter&&filter[0],
  toDate: filter&&filter[1],
};

const { isLoading, isError, data } = useQuery(['fetchData', params], () => fetchData({params:params}));
const [isLabel, setIsLabel] = useState(data);
useEffect(()=>{
setIsLabel(data)
},[data])


console.log('LLLSSs',isLabel&&isLabel?isLabel:isLabel);

  const chartRef = useRef();
  console.log("getDataFromStarts_OVERALLL_LABEL", data);

  console.log("getDataFromStarts_labels");
  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels:isLabel?Object.keys(isLabel):['totalSales','totalPurchase','totalDebit','totalCredit','revenue'],
        datasets: [
          {
            label: "value",
            data:data,
            
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(201, 203, 207, 0.2)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        title: {
          display: false,
          text: "",
        },
     

        plugins: {
          subtitle: {
            display: false,
          },
          datalabels: {
            display: false,
            color: "black",
            align: "top",
          },
          legend: {
            display: false
        },

          // datalabels: {
          //   anchor: 'end',
          //   align: 'top',
          //   formatter: function(value, context) {
          //     return value;
          //   }
          // }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });

    return () => {
      myChart.destroy();
    };
  }, [data]);



  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <button
          className={`${
            isDate == "firstmonth" ? "ghost_button_active " : "ghost_button"
          }`}
          onClick={() => {
            setIsDate("firstmonth");
            setStartDate("");
            setEndDate("");
            setFilter([currentDate, startOfMonth]);
          }}
        >
          1Mon
        </button>
        <button
          className={`${
            isDate == "secmonth" ? "ghost_button_active " : "ghost_button"
          }`}
          onClick={() => {
            setIsDate("secmonth");
            setStartDate("");
            setEndDate("");
            setFilter([currentDate, moment().subtract(2, 'months').format('YYYY-MM-DD')]);
          }}
        >
          2Mon
        </button>
        <button
          className={`${
            isDate == "thrmonth" ? "ghost_button_active " : "ghost_button"
          }`}
          onClick={() => {
            setIsDate("thrmonth");
            setStartDate("");
            setEndDate("");
            setFilter([currentDate,  moment().subtract(3, 'months').format('YYYY-MM-DD')]);
          }}
        >
          3Mon
        </button>

        <DatePicker
          className="barChartDateFiles"
          // id={startDateId}
          selected={startDate}
          selectsRange
          startDate={startDate}
          endDate={endDate}
          placeholderText="Select Date"
          // dateFormat={displayFormat}
          onChange={onChange}
          maxDate={new Date()}
          // locale={selectLocale(locale)}
          // customInput={<CustomInput />}
        />
      </div>
      <canvas ref={chartRef} />
    </div>
  );
};

export default StackedBarChart;
