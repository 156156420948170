import React from "react";
import { useState, useEffect } from "react";
import TableData from "./TableData";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Goback from "../../src/assets/image/GoBack.svg";
import Logo from "../../src/assets/image/Forward.svg";
import { TablePagination } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import ProductTable from "./ProductTable";
import { useQuery } from "react-query";
import { getProductDetail } from "../api/GlobalApi";
import { Controller, useForm } from "react-hook-form";

const ProductViews = () => {
  const navigate = useNavigate();

  const { data, isLoading, isError,refetch } = useQuery(
    "getProductDetail",
    getProductDetail
  );
 console.log('data',data);
 
  const {
    control,
    watch,
    formState: { errors },
  } = useForm();
  const [status, setStatus] = useState();
  const [searchProduct,setSearchProduct]=useState("")
  const { ProductSearch } = watch();
  const details =data && data?.data
  console.log(
    "details",
    status,
    data,
    ProductSearch,
    
  );
  const handleChange=(e)=>{
    setSearchProduct(e.target.value)
  }
  const filteredProducts = details?.filter((product)=>{
    return(
      product.productNameEnglish?.toLowerCase().startsWith(searchProduct?.toLowerCase())
    )
  }
 );
  return (
    <>
      <div className="container product_container">
        <div className="productViewHeadSection">
          <div className="productViewHeadBlock">
            <div>
              <img src={Goback} alt="backIcon" onClick={() => navigate(-1)}></img>
            </div>

            <div className="product_header">
              <h2 className="product_view">Product View</h2>
            </div>
          </div>

          <div className="productViewSearchFilterBlock">
            <div className="productViewSearchField">
              <Controller
                control={control}
                name="ProductSearch"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <input
                    type="search"
                    placeholder="Search Product"
                    value={value}
                    onChange={handleChange}
                  />
                )}
              />
            </div>
          </div>
          
        </div>

        <div>
          {/* <TableData data={filters} /> */}
          <ProductTable
            data={data}
            searchResult={filteredProducts}
           searchProduct={searchProduct}
            isLoading={isLoading}
            isError={isError}
            refetch={refetch}
          />
        </div>
      </div>
    </>
  );
};

export default ProductViews;
