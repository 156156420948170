import React from "react";

import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

export const SharePurchaseForm = async ({ purchaseData }) => {
  console.log("API", purchaseData);

  return await axios.post(
    `${process.env.REACT_APP_HOST}/purchases/addPurchase`,
    purchaseData
  );
};

export const UsePurchaseForm = (props) => {
  console.log("API DATA", props);
  return useMutation(SharePurchaseForm, {
    onSuccess: (data) => {
      console.log("usePurchaseForm", data);
      alert("Purchase Added Successfull");
    },
    onError: (err) => {
      console.log("MX_PAYMENT", err);
    },
  });
};

export const getOverAllReport = async ({ customerId, organization = null }) => {
  return await axios.get(
    `${process.env.REACT_APP_HOST}/sales/totalSalesFromStartOfMonth`
  );
};
export const getProductDetail = async ({ customerId, organization = null }) => {
  return await axios.get(`${process.env.REACT_APP_HOST}/products`);
};
export const getLastInvoice = async ({ customerId, organization = null }) => {
  // return await axios.get(`${process.env.REACT_APP_HOST}/invoice/last`);
  try {
    const response = await axios.get(`${process.env.REACT_APP_HOST}/invoice/last`);
    console.log('response',response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching the last invoice number:", error);
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};
export const getSalaryInvNo = async ({ customerId, organization = null }) => {
  return await axios.get(
    `${process.env.REACT_APP_HOST}/addsalaries/lastinvoiceentry`
  );
};
export const getPurchaseDetail = async ({
  customerId,
  organization = null,
}) => {
  return await axios.get(`${process.env.REACT_APP_HOST}/purchases`);
};
export const getPurchaseProduct = async ({
  customerId,
  organization = null,
}) => {
  return await axios.get(`${process.env.REACT_APP_HOST}/products/list`);
};
export const getRevenueReport = async ({ customerId, organization = null }) => {
  return await axios.get(`${process.env.REACT_APP_HOST}/revenue`);
};
export const getSalesReport = async ({ customerId, organization = null }) => {
  return await axios.get(
    `${process.env.REACT_APP_HOST}/sales/totalSalesFromStartOfMonth`
  );
};
export const getPurchasesReport = async ({
  customerId,
  organization = null,
}) => {
  return await axios.get(
    `${process.env.REACT_APP_HOST}/purchases/totalPurchaseFromStartOfMonth`
  );
};
export const getCreditReport = async ({ customerId, organization = null }) => {
  return await axios.get(
    `${process.env.REACT_APP_HOST}/sales/totalCreditAmount`
  );
};
export const getDebitReport = async ({ customerId, organization = null }) => {
  return await axios.get(
    `${process.env.REACT_APP_HOST}/purchases/totalDebitAmount`
  );
};
export const getSalaryView = async ({ customerId, organization = null }) => {
  return await axios.get(`${process.env.REACT_APP_HOST}/addsalaries`);
};
export const getDataFromStart = async ({ date, organization = null }) => {
  const params = {
    fromDate: date && date[0],
    toDate: date && date[1],
  };
  // console.log("DAte_formate_1mont",date);
  return await axios
    .get(`${process.env.REACT_APP_HOST}/dataFromStart`, { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // Handle error
      console.error("Error fetching data:", error);
    });
};
export const fetchData = async ({ params, setIsLabel }) => {
  console.log("params_data", params);
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_HOST}/dataFromStart`,
      { params }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

// export const getCustomerRegisterTable  = async ({
//   customerId,
//   organization = null,
// }) => {
//   return await axios.get(
//     `${process.env.REACT_APP_HOST}/companies`,
//   );
// };

export const deletePurchaseDetail = async () => {
  return await axios.delete(`${process.env.REACT_APP_HOST}/purchases/`);
};
export const getCustomerRegister = async ({
  customerId,
  organization = null,
}) => {
  return await axios.get(`${process.env.REACT_APP_HOST}/companies`);
};
export const headOfficeName = async ({ customerId, organization = null }) => {
  return await axios.get(
    `${process.env.REACT_APP_HOST}/companies/headofficetablesdata`
  );
};
//   Example

// export const getCustomerContactDetail = async ({
//     customerId,
//     organization = null,
//   }) => {
//     return await axiosWithAuth.get(
//       `${HOST}${organization}/customer/${customerId}/contact`,
//     );
//   };

//   export const createContactDetailsCustomerOverview = async ({
//     customerId,
//     organization = null,
//     ...contactData
//   }) => {
//     return await axiosWithAuth.post(
//       `${HOST}${organization}/customer/${customerId}/contact`,
//       contactData,
//     );
//   };

//   export const updateContactDetailsCustomerOverview = async ({
//     clientCode,
//     contactId,
//     data,
//     customerId,
//     organization = null,
//   }) => {
//     return await axiosWithAuth.put(
//       `${HOST}${organization}/customer/${customerId}/contact/${contactId}`,
//       data,
//       {
//         headers: {
//           appCode: APP_CODE,
//         },
//         params: {
//           clientCode,
//         },
//       },
//     );
//   };

// export const deleteContactDetailsCustomerOverview = async ({
//   clientCode,
//   contactId,
//   customerId,
//   organization = null,
// }) => {
//   return await axiosWithAuth.delete(
//     `${HOST}${organization}/customer/${customerId}/contact/${contactId}`,
//     {
//       headers: {
//         appCode: APP_CODE,
//       },
//       params: {
//         clientCode,
//       },
//     },
//   );
// };
