import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import InputField from "./InputField";
import ProductHeader from "./ProductHeader";
import deleteIcon from "../assets/image/deleteIcon.svg";
import Add from "../assets/image/Add.svg";
import { useLocation, useNavigate } from "react-router";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Goback from "../assets/image/GoBack.svg";
import BillingAddInputField from "./BillingAddInputField";
import DatePicker from "react-datepicker";
import moment from "moment";
import AlertModal from "./AlertModal";
import { getLastInvoice, getSalaryInvNo } from "../api/GlobalApi";

export default function SalaryAdd() {
  const { state } = useLocation();
  const [isCount, setIsCount] = useState(0);
  const [error, setError] = useState();
  const [open, setOpen] = useState(false);
  const [UpdateOpen, setUpdateOpen] = useState(false);
  console.log("error", error);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userForm: [
        {
          salaryPerDay: " ",
          presentDay: "",
          subTotal: "",
          managementFee: "",
          descriptionOfGoods: "  ",
          totalRs: " ",
          gstTax: " ",
          grandTotal: " ",
        },
      ],
    },
  });
  {
  }

  const { fields, append, remove } = useFieldArray({
    name: "userForm",
    control,
  });

  const invoiceNumberlist = useQuery("getSalesInvNumber", getLastInvoice);
  let billNum =
    invoiceNumberlist && invoiceNumberlist?.data
      ? invoiceNumberlist && invoiceNumberlist?.data
      : "";

  // let billNum =
  //   invoiceNumberlist &&
  //   invoiceNumberlist?.data &&
  //   invoiceNumberlist?.data?.data
  //     ? invoiceNumberlist &&
  //       invoiceNumberlist?.data &&
  //       invoiceNumberlist?.data?.data
  //     : [];

  console.log("billNum", billNum);

  useEffect(() => {
    if (state === null) {
      setValue("invoiceEntry", billNum + 1);
    }
  }, [billNum]);

  console.log("billNum", billNum);
  const editMrMrs = state?.customerName?.slice(0, 2);
  const EditCustomerName = state?.customerName.substring(
    state?.customerName.indexOf(",") + 1
  );
  console.log("start123", state);
  useEffect(() => {
    if (state) {
      // setValue('mrMrs',editMrMrs)
      setValue("customerName", EditCustomerName);
      setValue("contactNumber", state?.contactNumber);
      setValue("customerAddress", state?.customerAddress);
      setValue("pincode", state?.pincode);
      setValue("date", state?.date);
      setValue("district", state?.district);
      setValue("invoiceEntry", state?.invoiceEntry);

      if (state?.userForm) {
        remove();
        state.userForm.forEach((formItem, index) => {
          console.log("KKFFGGGLS", formItem);
          append({
            salaryPerDay: formItem?.salaryPerDay,
            presentDay: formItem?.presentDay,
            subTotal: formItem?.subTotal,
            managementFee: formItem?.managementFee,
            descriptionOfGoods: formItem?.descriptionOfGoods,
            totalRs: formItem?.totalRs,
            gstTax: formItem?.gstTax,
            grandTotal: formItem?.grandTotal,
          });
          // if (index === 0) {
          //   setValue(
          //     `userForm.${index}.Product`,
          //     state.descriptionOfGoods[index].descriptionOfGoods
          //   );
          //   setValue(
          //     `userForm.${index}.unitPrice`,
          //     state.salaryPerDay[index].salaryPerDay
          //   );
          //   setValue(
          //     `userForm.${index}.presentDay`,
          //     state.userForm[index].presentDay
          //   );
          //   setValue(
          //     `userForm.${index}.subTotal`,
          //     state.userForm[index].subTotal
          //   );
          //   setValue(
          //     `userForm.${index}.managementFee`,
          //     state.userForm[index].managementFee
          //   );
          //   setValue(
          //     `userForm.${index}.totalRs`,
          //     state.userForm[index].totalRs
          //   );
          //   setValue(
          //     `userForm.${index}.gstTax`,
          //     state.userForm[index].gstTax
          //   );
          //   setValue(
          //     `userForm.${index}.grandTotal`,
          //     state.userForm[index].grandTotal
          //   );
          // }
        });
      }
    }
  }, [state, setValue, append, remove]);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(`${process.env.REACT_APP_HOST}/addsalaries`, postData),
    {
      onSuccess: (data) => {
        setOpen("Salary entry has been successfully added.");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  console.log("isError", isError);
  const upData = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/addsalaries/${state?.id}`,
        postData
      ),
    {
      onSuccess: (data) => {
        // alert(" Update Successfully");
        // reset();
        //  console.error("Mutation failed:", error);
        // navigate("/salaryView");
        setUpdateOpen("Salary entry has been successfully updated.");
      },
      onError: (error) => {
        // alert(error)
        console("Mutation failed:", error);
      },
    }
  );
  const { userForm, unitPrice, discountPrice, quantity } = watch();

  const Submit = async (data) => {
    const value = {
      customerName: `${data?.mrMrs === undefined ? "" : data?.mrMrs}${
        data?.mrMrs === undefined ? "" : "."
      }${data?.customerName}`,
      customerAddress: data?.customerAddress,
      pincode: data?.pincode,
      contactNumber: data?.contactNumber,
      date: data?.date,
      district: data?.district,
      invoiceEntry: data?.invoiceEntry,
      userForm,
    };
    console.log(data);
    if (state && state.id) {
      // console.log('updatedata',data);
      upData.mutate(value);
    } else {
      mutate(value);
      // console.log('updatedata',value);
    }
    // console.log("data", data);
  };
  const watchFields = watch("userForm");
  const calculateSubTotal = (index) => {
    const salaryPerDay = watchFields[index]?.salaryPerDay || 0;
    const presentDay = watchFields[index]?.presentDay || 0;
    const subTotal = Number(salaryPerDay) * Number(presentDay);
    console.log("KKDDDLL", index, salaryPerDay, presentDay, subTotal);
    setValue(`userForm.${index}.subTotal`, subTotal.toString());
  };
  const calculateTotalAmount = (index) => {
    const salaryPerDay = watchFields[index]?.subTotal || 0;
    const presentDay = watchFields[index]?.managementFee || 0;
    const subTotal = Number(salaryPerDay) + Number(presentDay);
    console.log("KKDDDLL", index, salaryPerDay, presentDay, subTotal);
    setValue(`userForm.${index}.totalRs`, subTotal.toString());
  };
  const calculategrandTotal = (index) => {
    const salaryPerDay = watchFields[index]?.totalRs || 0;
    const presentDay = watchFields[index]?.gstTax || 0;
    const gstTotal = (Number(presentDay) * Number(salaryPerDay)) / 100;
    const subTotal = Number(salaryPerDay) + Number(gstTotal);
    console.log("KKDDDLL", index, salaryPerDay, presentDay, subTotal);
    setValue(`userForm.${index}.grandTotal`, subTotal.toString(), "");
  };
  console.log("isError_isError", isError);

  return (
    <div
      className="ps-2 main-container"
      style={{ width: "80%", overflowY: "hidden" }}
    >
      <div className="BillingAddHeader">
        <div className="Billing-Add-GoBackBtn">
          <img src={Goback} alt="no-image" onClick={() => navigate(-1)}></img>
        </div>

        <div className="billing-heading-viewblock">
          <div>
            <h3 className="Billng-Add-Heading">Salary Add</h3>
          </div>

          <div className="Billing-Add-ViewBlock">
            <button
              className="Billing-Add-ViewBtn"
              onClick={() => {
                navigate("/salaryView");
              }}
            >
              View
            </button>
          </div>
        </div>
      </div>
      <div
        className="input-container"
        style={{ height: "600px", overflowY: "scroll" }}
      >
        <form onSubmit={handleSubmit(Submit)}>
          <div className="BillingAddInputContainer">
            <div className="BillingAddInputField ">
              <label className="BillingAddInputLabel">Mr/Mrs</label>
              <Controller
                control={control}
                name="mrMrs"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <select
                    value={value}
                    onChange={onChange}
                    className="  text-capitalize billing_input_field"
                    placeholder="Select"
                    // style={{
                    //   "text-transform": "capitalize",
                    // }}
                    // style={ errors.selectHeadOffice ? { borderColor: "red" }
                    //       : {} }
                  >
                    <option value="">None</option>
                    <option value="Miss">Miss</option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                  </select>
                )}
              />
            </div>
            <div className="BillingAddInputField ">
              <label className="BillingAddInputLabel">
                Customer Name
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
              </label>
              <Controller
                control={control}
                name="customerName"
                render={(field) => (
                  <BillingAddInputField {...field} type="text" />
                )}
                rules={{ required: true }}
              />
            </div>
            <div className="BillingAddInputField">
              <label className="BillingAddInputLabel">
                Customer Address
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
              </label>
              <Controller
                control={control}
                name="customerAddress"
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <textarea
                    style={error ? { borderColor: "red" } : {}}
                    value={value}
                    onChange={onChange}
                    // {...field}
                    className=" billing_input_field"
                  ></textarea>
                  // render={(field) => (
                  //   <BillingAddInputField {...field} type="text" />
                )}
                rules={{ required: true }}
              />
            </div>
            <div className="BillingAddInputField">
              <label className="BillingAddInputLabel">
                District
                {/* <span style={{ color: "red" ,fontSize:"14px" }}>*</span> */}
              </label>
              <Controller
                control={control}
                name="district"
                render={(field) => (
                  <BillingAddInputField {...field} type="type" />
                )}
                rules={{ required: true }}
              />
            </div>
            <div className="BillingAddInputField">
              <label className="BillingAddInputLabel">
                Pincode
                {/* <span style={{ color: "red" ,fontSize:"14px" }}>*</span> */}
              </label>
              <Controller
                control={control}
                name="pincode"
                render={(field) => (
                  <BillingAddInputField {...field} type="type" />
                )}
                // rules={{ required: true }}
              />
            </div>
            <div
              className="BillingAddInputField"
              style={{ paddingRight: "12px" }}
            >
              <label className="BillingAddInputLabel">Sales Date</label>
              <Controller
                control={control}
                name="date"
                render={({ field }) => (
                  <DatePicker
                    className="billing_input_field "
                    {...field}
                    // placeholderText="Sales Date"
                    // dateFormat={moment().format('DD-MM-YYYY')}
                    showYearDropdown
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                  />
                )}
              />
            </div>
            <div className="BillingAddInputField">
              <label className="BillingAddInputLabel">
                Phone Number
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
              </label>
              <Controller
                control={control}
                name="contactNumber"
                render={(field) => (
                  <BillingAddInputField {...field} type="type" />
                )}
                rules={{ required: true }}
              />
            </div>
            <div className="BillingAddInputField">
              <label className="BillingAddInputLabel">
                Bill No
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
              </label>
              <Controller
                control={control}
                name="invoiceEntry"
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <input
                    type="text"
                    value={value}
                    onChange={onChange}
                    className="text-capitalize billing_input_field"
                    // disabled
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="">
            {fields.map((field, index) => (
              <div key={field.id}>
                <div
                  style={{
                    backgroundColor: "#F3F1F1",
                    marginBottom: "10px",
                    overflow: "auto",
                  }}
                >
                  <div className="d-flex">
                    <div className="salaryAddInputFieldContainer">
                      <label className="ProductLabel">
                        Description of Goods
                        {/* <span style={{ color: "red" ,fontSize:"14px" }}>*</span> */}
                      </label>
                      <Controller
                        control={control}
                        name={`userForm.${index}.descriptionOfGoods`}
                        render={(field) => (
                          <InputField
                            {...field}
                            addSalaryDescriptionInputCss="addSalaryDescriptionInputCss"
                            placeholder="Description "
                            type="type"
                          />
                        )}
                      />
                    </div>
                    <div
                      style={{
                        width: "100px",
                        height: "110px",
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "center",
                        paddingBottom: "10px",
                      }}
                      onClick={() => {
                        append({
                          salaryPerDay: " ",
                          presentDay: "",
                          subTotal: "",
                          managementFee: "",
                          descriptionOfGoods: "  ",
                          totalRs: " ",
                          gstTax: " ",
                          grandTotal: " ",
                        });
                      }}
                    >
                      <img src={Add} alt="addIcon" />
                    </div>

                    <img
                      style={{
                        width: "40px",
                        height: "100px",
                        paddingTop: "45px",
                        display: index === 0 ? "none" : "block",
                      }}
                      src={deleteIcon}
                      alt="delete"
                      {...register(`userForm.${index}.image`)}
                      onClick={() => remove(index)}
                    />
                  </div>

                  <div style={{ display: "flex", "flex-wrap": "wrap" }}>
                    <div className="salaryAddInputFieldContainer">
                      <label className="ProductLabel">
                        Salary per day
                        <span style={{ color: "red", fontSize: "14px" }}>
                          *
                        </span>
                      </label>
                      <Controller
                        control={control}
                        name={`userForm.${index}.salaryPerDay`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <input
                            style={error ? { borderColor: "red" } : {}}
                            className="billing_input_field"
                            {...field}
                            type="number"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              calculateSubTotal(index);
                            }}
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>

                    <div className="salaryAddInputFieldContainer">
                      <label className="ProductLabel">
                        Present Day
                        <span style={{ color: "red", fontSize: "14px" }}>
                          *
                        </span>
                      </label>
                      <Controller
                        control={control}
                        name={`userForm.${index}.presentDay`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <input
                            className="billing_input_field"
                            {...field}
                            style={error ? { borderColor: "red" } : {}}
                            type="number"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              calculateSubTotal(index);
                            }}
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>

                    <div className="salaryAddInputFieldContainer">
                      <label className="ProductLabel">Sub Total</label>
                      <Controller
                        control={control}
                        name={`userForm.${index}.subTotal`}
                        render={({ field: { onChange, value } }) => (
                          <input
                            className="billing_input_field"
                            {...field}
                            type="number"
                            onChange={(e) => {
                              onChange(e);
                              calculateTotalAmount(index);
                            }}
                            value={value}
                          />
                        )}
                      />
                    </div>
                    {/* <div className="salaryAddInputFieldContainer">
                      <label className="ProductLabel">Management Fee</label>
                      <Controller
                        control={control}
                        name={`userForm.${index}.managementFee`}
                        render={({ field: { onChange, value } }) => (
                          <input
                            className="billing_input_field"
                            {...field}
                            type="number"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              calculateTotalAmount(index);
                            }}
                          />
                        )}
                      />
                    </div>

                    <div className="salaryAddInputFieldContainer">
                      <label className="ProductLabel">Total Amount</label>
                      <Controller
                        control={control}
                        name={`userForm.${index}.totalRs`}
                        render={({ field: { onChange, value } }) => (
                          <input
                            className="billing_input_field"
                            {...field}
                            type="number"
                            onChange={(e) => {
                              onChange(e);
                              calculateTotalAmount(index);
                            }}
                            value={value}
                          />
                        )}
                      />
                    </div>

                    <div className="salaryAddInputFieldContainer">
                      <label className="ProductLabel">GST</label>
                      <Controller
                        control={control}
                        name={`userForm.${index}.gstTax`}
                        render={({ field: { onChange, value } }) => (
                          <input
                            className="billing_input_field"
                            {...field}
                            type="number"
                            onChange={(e) => {
                              onChange(e);
                              calculategrandTotal(index);
                            }}
                            value={value}
                          />
                        )}
                      />
                    </div>

                    <div className="salaryAddInputFieldContainer">
                      <label className="ProductLabel">Grand Total</label>
                      <Controller
                        control={control}
                        name={`userForm.${index}.grandTotal`}
                        render={(field) => (
                          <InputField
                            {...field}
                            type="text"
                            addSalaryInputCss="mt-0 billing_input_field"
                          />
                        )}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            {/* <input className="inputSubmit-Btn" type="submit" /> */}
            <button className="inputSubmit-Btn mt-4 ms-2" type="submit">
              {isLoading ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      </div>
      <AlertModal
        open={open}
        setOpen={setOpen}
        // handleClose={handleClose}
        setUpdateOpen={setUpdateOpen}
        UpdateOpen={UpdateOpen}
        navigate={"/salaryView"}
      />
    </div>
  );
}
