import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import Goback from "../../src/assets/image/GoBack.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import moment from "moment";
import EditIcon from "../assets/image/edit.png";
import DeleteIcon from "../assets/image/trash.png";
import { getSalaryView } from "../api/GlobalApi";
import { useQuery } from "react-query";
import axios from "axios";
import printIcon from "../assets/image/Print.svg";
import AlertModal from "./AlertModal";
import showBill from "../assets/image/pasShow.png";
export default function SalaryView(props) {
  // const searchProduct =[{}]
  const [deleteIndex, setDeleteIndex] = useState([]);
  const [printIndex, setPrintIndex] = useState([]);
  const [searchProduct, setSearchProduct] = useState("");
  const { control } = useForm();
  const navigate = useNavigate();
  const [page, pageChange] = useState(0);
  const [rowperpage, rowperpageChange] = useState(6);
  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(null);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [deleteSuccessfully, setDeleteSuccessfully] = useState(false);
  let handlepageChange = (event, newpage) => {
    pageChange(newpage);
  };
  let handlerowsperpageChange = (event) => {
    rowperpageChange(+event.target.value);
    pageChange(0);
  };
  const handleChange = (e) => {
    setSearchProduct(e.target.value);
  };

  const { data, isLoading, isError, refetch } = useQuery(
    "getProductDetail",
    getSalaryView
  );
  const salaryView = data && data?.data;
  const filteredProducts = salaryView?.filter((product) => {
    return product.customerName
      ?.toLowerCase()
      .startsWith(searchProduct?.toLowerCase());
  });
  const HandlerDelete = async (id) => {
    console.log("deleteIndex__ID", id, "deleteIndex", deleteIndex);

    // const confirmDelete = window.confirm("Are you sure you want to delete?");
    // console.log("DeletedId", confirmDelete, deleteIndex);
    if (deleteIndex && deleteConfirmAlert.length > 0) {
      console.log("TRUE ID DELETE deleteIndex ");
      // deleteIndex &&
      // deleteIndex?.map(async (val, i) => {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_HOST}/addsalaries/delete/bulk`,
          {
            data: deleteIndex,
          }
        );
        refetch();
        document.getElementById("myCheckbox").checked = false;
        setDeleteSuccessfully(true);
        setDeleteIndex([]);
        return response.data;
      } catch (error) {
        console.log("Error", error);
      }

      console.log("Deleted!");
    }
    console.log("deleteConfirmAlert", deleteConfirmAlert);
    if (deleteConfirmAlert) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_HOST}/addsalaries/delete/bulk`,
          {
            data: [deleteConfirmAlert],
          }
        );
        refetch();
        setDeleteSuccessfully(true);
        return response.data;
      } catch (error) {
        console.log("Error", error);
      }
    }
  };
  const printProduct = async () => {
    sessionStorage.setItem("myValue", JSON.stringify(printIndex));
    navigate("/ProductView/Invoice");
  };
  console.log("salaryView", salaryView);
  return (
    <div className="container product_container">
      <div className="productViewHeadSection ">
        <div className="productViewHeadBlock">
          <div>
            <img src={Goback} onClick={() => navigate(-1)}></img>
          </div>

          <div className="product_header">
            <h2 className="product_view">Salary View</h2>
          </div>
        </div>

        <div className="productViewSearchFilterBlock">
          <div className="productViewSearchField">
            <Controller
              control={control}
              name="ProductSearch"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <input
                  type="search"
                  placeholder="Search"
                  value={value}
                  onChange={handleChange}
                />
              )}
            />
          </div>
        </div>
        <div className="  deleteBtnCss mt-3">
          <button
            onClick={() => {
              setDeleteConfirmAlert(deleteIndex);
            }}
            className="deleteBtn me-2"
            style={{
              display: deleteIndex < 1 ? "none" : "block",
            }}
          >
            Delete
          </button>
          {/* <button
            onClick={() => {
              // HandlerDelete();
              printProduct();
            }}
            className="deleteBtn"
            style={{
              display: printIndex < 1 ? "none" : "block",
            }}
          >
            Print
          </button> */}
        </div>
      </div>
      <>
        <div className="container">
          <div className="row">
            <div className="product_table">
              <TableContainer className="product_table_container_sticky">
                <Table>
                  <TableHead className="product_heading_sticky ">
                    <TableRow>
                      {/* <TableCell className="product_table_heading_first_sticky">
                        S.No
                      </TableCell> */}
                      <TableCell className="sales_table_heading">
                        Bill No
                      </TableCell>
                      <TableCell className="sales_table_heading">
                        Customer Name
                      </TableCell>
                      <TableCell className="sales_table_heading">
                        Customer Address
                      </TableCell>
                      <TableCell className="sales_table_heading">
                        Contact
                      </TableCell>
                      <TableCell className="sales_table_heading">
                        Pin Code
                      </TableCell>
                      <TableCell className="sales_table_heading">
                        Sales Date
                      </TableCell>
                      <TableCell className="sales_table_heading">
                        Description
                      </TableCell>
                      <TableCell className="sales_table_heading">
                        Edit/Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow
                        style={{ height: "180PX", position: "relative" }}
                      >
                        <div class="wrapper">
                          <div class="circle"></div>
                          <div class="circle"></div>
                          <div class="circle"></div>
                          <div class="shadow"></div>
                          <div class="shadow"></div>
                          <div class="shadow"></div>
                          <span>Loading</span>
                        </div>
                      </TableRow>
                    ) : isError ? (
                      <TableRow>
                        <p
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: 800,
                          }}
                        >
                          No Network...
                        </p>
                      </TableRow>
                    ) : searchProduct?.length === 0 ? (
                      props &&
                      salaryView
                        ?.slice(
                          page * rowperpage,
                          page * rowperpage + rowperpage
                        )
                        .map((val, i) => {
                          console.log("currentpage_val", val);
                          return (
                            <>
                              {console.log("val", val)}
                              <TableRow key={i}>
                                {/* <TableCell className="product_table_data_first_sticky">
                                  {i + 1}
                                </TableCell> */}
                                <TableCell className="sales_table_data">
                                  {val.invoiceEntry}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  {val.customerName}
                                </TableCell>
                                <TableCell className="sales_table_data--productName">
                                  {val.customerAddress}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  {val.contactNumber}
                                </TableCell>

                                <TableCell className="sales_table_data">
                                  {val.pincode}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  {val.date}
                                </TableCell>
                                <TableCell className="sales_table_data--productName">
                                  {val?.userForm?.map((items, i) => {
                                    console.log("items", items);
                                    return `${items?.descriptionOfGoods.toString()},`;
                                  })}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  <img
                                    src={EditIcon}
                                    alt=""
                                    className="product_edit_icon"
                                    onClick={() =>
                                      navigate("/salaryAdd", {
                                        state: val,
                                      })
                                    }
                                  ></img>

                                  <img
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "10px",
                                    }}
                                    src={showBill}
                                    alt="print icon"
                                    className="sales_print_icon"
                                    value={val}
                                    onClick={() => {
                                      //  navigate(
                                      //   "/ProductView/Invoice")
                                      sessionStorage.setItem(
                                        "myValue",
                                        JSON.stringify(val)
                                      );
                                      navigate("/ProductView/Invoice");
                                    }}
                                  />
                                  <input
                                    id="myCheckbox"
                                    onClick={() => {
                                      setDeleteIndex([...deleteIndex, val.id]);
                                      setPrintIndex([...printIndex, val]);
                                    }}
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      marginRight: "10px",
                                    }}
                                    type="checkBox"
                                  />

                                  <img
                                    style={{ marginBottom: "10px" }}
                                    src={DeleteIcon}
                                    alt=""
                                    className="product_delete_icon"
                                    onClick={() =>
                                      setDeleteConfirmAlert(val.id)
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })
                    ) : filteredProducts &&
                      filteredProducts &&
                      filteredProducts.length > 0 ? (
                      filteredProducts
                        ?.slice(
                          page * rowperpage,
                          page * rowperpage + rowperpage
                        )
                        .map((e, i) => {
                          return (
                            <>
                              <TableRow key={i}>
                                {/* <TableCell className="product_table_data_first_sticky">
                                  {i + 1}
                                </TableCell> */}
                                <TableCell className="sales_table_data">
                                  {e.invoiceEntry}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  {e.customerName}
                                </TableCell>
                                <TableCell className="sales_table_data--customer">
                                  {e.customerAddress}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  {e.contactNumber}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  {e.pincode}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  {e.date}
                                </TableCell>
                                <TableCell className="sales_table_data--productName">
                                  {e?.userForm?.map((items, i) => {
                                    console.log("items", items);
                                    return `${items?.descriptionOfGoods.toString()},`;
                                  })}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  <img
                                    src={EditIcon}
                                    alt=""
                                    className="product_edit_icon"
                                    onClick={() =>
                                      navigate("/salaryAdd", {
                                        state: e,
                                      })
                                    }
                                  ></img>

                                  <img
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "10px",
                                    }}
                                    src={showBill}
                                    alt="print icon"
                                    className="sales_print_icon"
                                    value={e}
                                    onClick={() => {
                                      //  navigate(
                                      //   "/ProductView/Invoice")
                                      sessionStorage.setItem(
                                        "myValue",
                                        JSON.stringify(e)
                                      );
                                      navigate("/ProductView/Invoice");
                                    }}
                                  />
                                  <input
                                    id="myCheckbox"
                                    onClick={() => {
                                      setDeleteIndex([...deleteIndex, e.id]);
                                      // setPrintIndex([...printIndex, e]);
                                    }}
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      marginRight: "10px",
                                    }}
                                    type="checkBox"
                                  />

                                  <img
                                    style={{ marginBottom: "10px" }}
                                    src={DeleteIcon}
                                    alt=""
                                    className="product_delete_icon"
                                    onClick={() => setDeleteConfirmAlert(e.id)}
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })
                    ) : (
                      <h4 style={{ width: "190%" }}> No record found</h4>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>

        <div className="container footer_section">
          {props.searchResult?.length == 0 ? (
            <div className="row mt-3">
              <div className="col-6">
                <p className="product_paragraph_footer">
                  Showing {page + 1} to {rowperpage} of {data?.length} entries
                </p>
              </div>
              <div className="col-6">
                <TablePagination
                  rowsPerPageOptions={[data?.length]}
                  rowsPerPage={rowperpage}
                  page={page}
                  count={data?.length}
                  component="div"
                  onPageChange={handlepageChange}
                  onRowsPerPageChange={handlerowsperpageChange}
                ></TablePagination>
              </div>
            </div>
          ) : (
            <div className="row mt-3">
              <div className="col-6">
                <p className="product_paragraph_footer">
                  Showing {page + 1} of{" "}
                  {Number(data && data?.data.length / 6).toFixed()} entries{" "}
                  {Number(data && data?.data.length / 6).toFixed() > 1
                    ? "Pages"
                    : "Page"}
                </p>
              </div>
              <div className="col-6">
                <TablePagination
                  rowsPerPageOptions={[data?.data.length]}
                  rowsPerPage={rowperpage}
                  page={page}
                  count={data?.data.length}
                  component="div"
                  onPageChange={handlepageChange}
                  onRowsPerPageChange={handlerowsperpageChange}
                ></TablePagination>
              </div>
            </div>
          )}
        </div>
      </>
      <AlertModal
        deleteConfirmAlert={deleteConfirmAlert}
        setDeleteConfirmAlert={setDeleteConfirmAlert}
        setDeleteSuccessfully={setDeleteSuccessfully}
        deleteSuccessfully={deleteSuccessfully}
        yesOnClick={() => {
          setConfirmAlert(true);
          setDeleteConfirmAlert(false);
          HandlerDelete();
        }}
      />
    </div>
  );
}

// descriptionOfGoods: "90";
// grandTotal: 90;
// gstTax: 90;
// managementFee: 9.99;
// presentDay: "CAR";
// salaryPerDay: null;
// subTotal: 99.99;
// totalRs: 1;
