import React, { useEffect, useState } from "react";
import BackIcon from "../assets/productViewImages/Back-icon.png";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { getCustomerRegister } from "../api/GlobalApi";

export default function ProductHeader(props) {
  const navigate = useNavigate();
  const [shouldFetch, setShouldFetch] = useState(false);
  const { register, handleSubmit, control, setValue, reset, watch } = useForm(
    {}
  );

  const GetcustomerRegister = useQuery(
    "customerResisterTable",
    getCustomerRegister
  );

  let details =
    GetcustomerRegister &&
    GetcustomerRegister?.data &&
    GetcustomerRegister?.data?.data
      ? GetcustomerRegister &&
        GetcustomerRegister?.data &&
        GetcustomerRegister?.data?.data
      : [];

  const subOfficeName = details?.filter((item) => {
    return item?.companyType === "subOffice";
  });
  const { subOffice } = watch();
  console.log("subOffice", subOffice);
  // const [isValue, setIsValue] = useState();

  useEffect(() => {
    if (subOffice !== undefined) {
      setShouldFetch(true);
      // setIsValue(item);
    }
  }, [subOffice]);
  //  API Start
  const branchAPI = async ({ params }) => {
    console.log("params_data", params);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST}/sales/branchtotal`,
        { params }
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };
  const { isLoading, isError, data } = useQuery(
    ["user", subOffice],
    () => branchAPI({ params: { subOffice: subOffice } }),
    {
      enabled: shouldFetch,
      onSettled: () => setShouldFetch(false),
      onSuccess: (data) => {
        console.log("DATA_LOGINSUCESS", data);
        props.setBranchValue(data)
      },
      onError: (data) => {
        setShouldFetch(false);
      },
    }
  );
  //  API End
  console.log("data", data);

  return (
    <div className="productViewMainContainer-header">
      {props.back ? (
        <div>
          <img
            className={`Add-product-GoBack-image ${props?.backButtonClassName}`}
            onClick={() => {
              navigate(-1);
            }}
            src={BackIcon}
            alt="Go Back"
          />
        </div>
      ) : (
        <></>
      )}

      <div className={`productViewContainer-header ${props?.dashBoardHeader}`}>
        <div className={`productView-name ${props?.name} `}>{props.label}</div>
        {props.productView ? (
          <div>
            {/* <Button type='submit' value='Submit'/> */}
            <div
              onClick={() => {
                navigate("/ProductView/ProductTable");
              }}
              className="ProductView-btn"
            >
              View
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div>
        {props.dashBoardHeaderBranchReport && (
          <div>
            <Controller
              control={control}
              name="subOffice"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <select
                  value={value}
                  onChange={onChange}
                  // {...field}
                  // className="input-datePickerField"
                  className="ms-4 dashBoardInputCss"
                  style={{ "text-transform": "capitalize" }}
                >
                  {/* <option> </option>
                  <option value="Vinmar pools Kelambakkam">
                    Vinmar pools Kelambakkam
                  </option>
                  <option value="Bag">bag</option>
                  <option value="Unit">unit</option>
                  <option value="Sft">sft</option> */}
                  <option value="">--Branch Sales Report--</option>
                  {subOfficeName?.map((data, index) => (
                    <option key={index} value={data?.shopName}>
                      {data.shopName}
                    </option>
                  ))}
                </select>
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
}
